import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material-module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import { AppDrawerComponent } from "./layouts/drawer/drawer.component";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppDrawerDirective } from './share/directive/app-drawer.directive';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CustomDirectiveModule } from "./share/directive/custom-directive.module";
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from "@angular/fire/compat";

@NgModule({
  declarations: [
    AppComponent,
    AppFullComponent,
    AppHeaderComponent,
    AppDrawerComponent,
    AppDrawerDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    DashboardModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    CustomDirectiveModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
