import { Directive, HostListener } from '@angular/core';
import { DrawerService } from '../service/drawer.service';

@Directive({
  selector: '[appAppDrawer]'
})
export class AppDrawerDirective {

  constructor(private drawerService: DrawerService) { }
  @HostListener('click', []) onClick() {
    this.drawerService.drawer.close();
  }
}
