<div class="container-bg">


    <div class="close" (click)="close()">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <svg viewBox="0 0 36 36" class="circle">
            <path stroke-dasharray="100, 100" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
        </svg>
    </div>

    <div>
        <img src="assets/image/register-bg.png" class="register-img" alt="">
    </div>
    <div class="form-field">
        <div class="welcome">
            WELCOME, {{ frmGroup?.value?.name }}
        </div>
        <ng-container *ngIf="!registered">
            <form [formGroup]="frmGroup">

                <mat-form-field appearance="outline">
                    <input matInput type="text" placeholder="Name" formControlName="name" autocomplete="off">
                    <mat-error>Name is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off">
                    <mat-error>Invalid Email</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <ngx-mat-intl-tel-input [onlyCountries]="['my']" class="tel" inputPlaceholder="Contact No."
                        [enablePlaceholder]="true" formControlName="contact">
                    </ngx-mat-intl-tel-input>
                    <mat-error>Invalid Contact No.</mat-error>
                </mat-form-field>

                <section style="width:100%">
                    <mat-checkbox formControlName="pdpa">
                        <span style="color:#7a706f;white-space: pre-wrap;">I have read and agreed with the <span
                                (click)="openDocDialog()" style="color: #7a706f; text-decoration: underline;">Terms of
                                Service,
                                Privacy Policy</span> and <span (click)="openDocDialog()" style="color: #7a706f;text-decoration: underline;">PDPA</span>
                            of the Organizer and or its partner and/or partner developers.
                        </span>
                    </mat-checkbox>
                </section>
                <button mat-flat-button class="full-btn" (click)="register()" [disabled]="!frmGroup.valid">
                    Register
                </button>
            </form>
        </ng-container>
        <ng-container *ngIf="registered">
            <div class="register-seat-container">
                <!-- <img src="assets/image/chair.png" alt=""> -->
                <div class="seat-container">
                    <span class="register-label">
                        <!-- Table -->
                    </span>
                    <span class="register-seat">
                        <span *ngIf="data?.table1">Table 1. {{data?.table1}}</span>
                        <span *ngIf="data?.table2">Table 2. {{data?.table2}}</span>
                    </span>
                </div>
            </div>
            <button mat-flat-button class="full-btn" (click)="close()">
                Back Home
            </button>
        </ng-container>
    </div>


</div>