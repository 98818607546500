import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators"
import { UserData } from "src/app/dashboard/dashboard/dashboard.component";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root',
})
export class ParticipantService {

    collectionReference: string = "participants";
    participantCollection: AngularFirestoreCollection<UserData>;

    data = [
        {
            "name": "Michael Cheang (The Star)",
            "table1": "L1",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Colin Gomez  (Prestige)",
            "table1": "L2",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Rachel Soh (PIN Prestige)",
            "table1": "L3",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ashley Lazaroo (The Grid Asia)",
            "table1": "L4",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Fernando Fong (TRP)",
            "table1": "L5",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kelvin Tan (L'Officiel)",
            "table1": "L6",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Janice The (The Peak)",
            "table1": "L7",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jack Goh",
            "table1": "L8",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Juwei Teoh",
            "table1": "L9",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lim Yi Chiann (China Press)",
            "table1": "L10",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Calvin Yong (Buro)",
            "table1": "L11",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andrew's Guest",
            "table1": "L12",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andrew Neo",
            "table1": "L13",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Law Ze Sean",
            "table1": "L14",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Shik Pei Yen",
            "table1": "L15",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Breitling (Wei Wei)",
            "table1": "L16",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Breitling's Guest",
            "table1": "L17",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jaya Grocer",
            "table1": "L18",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jaya Grocer",
            "table1": "L19",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L20",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L21",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L22",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L23",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lim Sook Chean",
            "table1": "L24",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lai Ming",
            "table1": "L25",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ong Kee Wah (Sin Chew)",
            "table1": "L26",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Chris Wong (Kingssleeve)",
            "table1": "L27",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Elizabeth Yaw (Q-E3)",
            "table1": "L28",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Natalie Khoo (FirstClasse)",
            "table1": "L20",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Alicia Choo (Robb Report)",
            "table1": "L30",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "JYRI (WG&S)",
            "table1": "L31",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lainey Loh (Tatler)",
            "table1": "L32",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ronn Tan (Lifestyle Asia)",
            "table1": "L33",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Nana",
            "table1": "L34",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Yan Joon Wing (Men's Folio)",
            "table1": "L35",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "The Edge (Options)",
            "table1": "L36",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Desmond's Guest",
            "table1": "L37",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Desmond Low",
            "table1": "L38",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jordan's Guest",
            "table1": "L39",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jordan Lim",
            "table1": "L40",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Yong Tze Liang",
            "table1": "L41",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Summer Chia",
            "table1": "L42",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Boozeit",
            "table1": "L43",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Boozeit",
            "table1": "L44",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L45",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L46",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L47",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "L48",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "John Lee",
            "table1": "L49",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Terrance Tea",
            "table1": "L50",
            "table2": "",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Aaron Chia",
            "table1": "",
            "table2": "R51",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Khew Choon Teck",
            "table1": "",
            "table2": "R52",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Samuel Choong",
            "table1": "",
            "table2": "R53",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Samuel's Guest",
            "table1": "",
            "table2": "R54",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kate's Guest",
            "table1": "",
            "table2": "R55",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kate Tan",
            "table1": "",
            "table2": "R56",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Felix Yang",
            "table1": "",
            "table2": "R57",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Felix's Guest",
            "table1": "",
            "table2": "R58",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Danny Ong",
            "table1": "",
            "table2": "R59",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Teoh Weng Chai",
            "table1": "",
            "table2": "R60",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jaime Low",
            "table1": "",
            "table2": "R61",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Zac Low",
            "table1": "",
            "table2": "R62",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Shina Goh",
            "table1": "",
            "table2": "R63",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Addy Ng",
            "table1": "",
            "table2": "R64",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Benjamin's Guest",
            "table1": "",
            "table2": "R65",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Benjamin Lau",
            "table1": "",
            "table2": "R66",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Dato Cheah Kah Lip",
            "table1": "",
            "table2": "R67",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Dato Cheah's Guest",
            "table1": "",
            "table2": "R68",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Tan Chee Sin",
            "table1": "",
            "table2": "R69",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Chee Sin's Guest",
            "table1": "",
            "table2": "R70",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Khor Quo Qian",
            "table1": "",
            "table2": "R71",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Anderlyn Cheng",
            "table1": "",
            "table2": "R72",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Louis Chu",
            "table1": "",
            "table2": "R73",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Audrey Ng",
            "table1": "",
            "table2": "R74",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Daniel Ng",
            "table1": "",
            "table2": "R75",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Wong Seong Seong",
            "table1": "",
            "table2": "R76",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Peter Foster",
            "table1": "",
            "table2": "R77",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jimmy Low",
            "table1": "",
            "table2": "R78",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Brian Lau",
            "table1": "",
            "table2": "R79",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Thiru",
            "table1": "",
            "table2": "R80",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Dhashna",
            "table1": "",
            "table2": "R81",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Hiu Chii Ren",
            "table1": "",
            "table2": "R82",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Hiu's Guest",
            "table1": "",
            "table2": "R83",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Looi Hong Weei",
            "table1": "",
            "table2": "R84",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Tai Yee Chee",
            "table1": "",
            "table2": "R85",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jeffrey Low",
            "table1": "",
            "table2": "R86",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Winnie Chee",
            "table1": "",
            "table2": "R87",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Alvin's Guest",
            "table1": "",
            "table2": "R88",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Alvin Lee",
            "table1": "",
            "table2": "R89",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kimi Lau",
            "table1": "",
            "table2": "R90",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Datuk Bill Tan",
            "table1": "",
            "table2": "R91",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andrew Heng",
            "table1": "",
            "table2": "R92",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andrew's Guest",
            "table1": "",
            "table2": "R93",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andy How",
            "table1": "",
            "table2": "R94",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andy's Guest",
            "table1": "",
            "table2": "R95",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Henrick Choo",
            "table1": "",
            "table2": "R96",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Henrick's Guest",
            "table1": "",
            "table2": "R97",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Victor Ng",
            "table1": "",
            "table2": "R98",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lim Meng Quan",
            "table1": "",
            "table2": "R99",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Bui Thi Ngoc",
            "table1": "",
            "table2": "R100",
            "date": "20/11/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        }
    ];


    constructor(
        private afs: AngularFirestore
    ) {
        this.participantCollection = this.afs.collection<UserData>(this.collectionReference);

    }

    getListing() {
        return this.participantCollection.valueChanges({ idField: 'id' }) as Observable<UserData[]>;
    }

    update(id, email, contact, name) {
        this.participantCollection.doc(id).update({ name: name, email: email, contact: contact });
    }

    deleteAll() {
        this.participantCollection.get().subscribe(x => {
            x.docs.forEach(elm => {
                elm.ref.delete();
            })
        });
    }

    add() {
        console.log(this.data);

        let i = 1;
        this.data.forEach(x => {
            console.log(i)
            i++;
            this.participantCollection.add({
                type: "1",
                name: x.name,
                table1: x.table1 || "",
                table2: x.table2 || "",
                email: "",
                contact: "",
                date: x.date
            }).then(x=>{
                console.log(x)
            })
        })
    }
}