import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppFullComponent } from "./layouts/full/full.component";

const routes: Routes = [
  {
    path: "",
    component: AppFullComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      }
    ],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled' //scroll to the top
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
