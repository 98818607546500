import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators"
import { UserData } from "src/app/dashboard/dashboard/dashboard.component";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root',
})
export class ParticipantService {

    collectionReference: string = "participants";
    participantCollection: AngularFirestoreCollection<UserData>;

    data = [
        {
            "name": "Benjamin Lau",
            "table1": "",
            "table2": "25",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jeffrey Chan",
            "table1": "",
            "table2": "18",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Sam Leong",
            "table1": "",
            "table2": "17",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Shun Ming",
            "table1": "",
            "table2": "16",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Stanley Leong",
            "table1": "",
            "table2": "24",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Edmond Teh",
            "table1": "",
            "table2": "23",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Andy How",
            "table1": "",
            "table2": "28",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Hiu Chii Ren",
            "table1": "",
            "table2": "26",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "BRIAN",
            "table1": "",
            "table2": "19",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "",
            "table2": "29",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Infinite Wines",
            "table1": "",
            "table2": "30",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Zac Low",
            "table1": "",
            "table2": "22",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Winson Ooi",
            "table1": "",
            "table2": "21",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Vanessa Lim",
            "table1": "",
            "table2": "20",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "EDWIN ",
            "table1": "",
            "table2": "27",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Vanessa",
            "table1": "8",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jimmy Low",
            "table1": "7",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ronn Tan ",
            "table1": "15",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Michael Cheang ",
            "table1": "1",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kelvin Tan ",
            "table1": "10",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "JYRI",
            "table1": "3",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Emmelyn Mah ",
            "table1": "12",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lorraine Chai ",
            "table1": "11",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Amanda Fung ",
            "table1": "6",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Wang Wei-Yu",
            "table1": "9",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Daniel Fernandez",
            "table1": "13",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Aaron Pereira ",
            "table1": "2",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "EuVin Tan ",
            "table1": "14",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Juwei Teoh ",
            "table1": "5",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Brain See ",
            "table1": "4",
            "table2": "",
            "date": "28/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Goh Wee Peng",
            "table1": "",
            "table2": "26",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Toh Chin Huat",
            "table1": "",
            "table2": "25",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Toh Guat Siew",
            "table1": "",
            "table2": "18",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lim Fang Hu",
            "table1": "",
            "table2": "17",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Wendy Chow",
            "table1": "",
            "table2": "24",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Teoh Weng Chai",
            "table1": "",
            "table2": "19",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ang Ming Tat",
            "table1": "",
            "table2": "20",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Brian ",
            "table1": "",
            "table2": "27",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Emily Cheah",
            "table1": "",
            "table2": "29",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Seow Ren Yih ",
            "table1": "",
            "table2": "21",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "James ",
            "table1": "",
            "table2": "22",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Robert Low",
            "table1": "",
            "table2": "23",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Hugo Aik",
            "table1": "",
            "table2": "30",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Desmond Low ",
            "table1": "",
            "table2": "28",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ng",
            "table1": "1",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ng Wei Seng",
            "table1": "2",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ong Chai Leng",
            "table1": "3",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ng Zi Wei",
            "table1": "4",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Benjamin Chua ",
            "table1": "5",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ellie",
            "table1": "6",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Kit ",
            "table1": "7",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jenifer Wong",
            "table1": "8",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Lok Tian ",
            "table1": "9",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Robbert ",
            "table1": "10",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Wayne Low ",
            "table1": "11",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Madeline chow",
            "table1": "12",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jack Lau",
            "table1": "13",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Jun Loh",
            "table1": "14",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Ho Jin Cheng",
            "table1": "15",
            "table2": "",
            "date": "29/5/2024",
            "email": "",
            "mobileNo": "",
            "type": ""
        },
        {
            "name": "Wong Mun Kit",
            "table1": "16",
            "table2": "",
            "date": "29/5/2024"
        }
    ];


    constructor(
        private afs: AngularFirestore
    ) {
        this.participantCollection = this.afs.collection<UserData>(this.collectionReference);

    }

    getListing() {
        return this.participantCollection.valueChanges({ idField: 'id' }) as Observable<UserData[]>;
    }

    update(id, email, contact, name) {
        this.participantCollection.doc(id).update({ name: name, email: email, contact: contact });
    }

    deleteAll() {
        this.participantCollection.get().subscribe(x => {
            x.docs.forEach(elm => {
                elm.ref.delete();
            })
        });
    }

    add() {
        console.log(this.data);

        let i = 1;
        this.data.forEach(x => {
            console.log(i)
            i++;
            this.participantCollection.add({
                type: "1",
                name: x.name,
                table1: x.table1 || "",
                table2: x.table2 || "",
                email: "",
                contact: "",
                date: x.date
            }).then(x=>{
                console.log(x)
            })
        })
    }
}