import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TableComponent } from "./table/table.component";
import { RegisterSheetComponent } from './register-sheet/register-sheet.component';
import { environment } from "../../environments/environment";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire/compat";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { MatTableExporterModule } from "mat-table-exporter";
import { DocDialogDialog } from "./doc-dialog/doc-dialog";

@NgModule({
    declarations: [DashboardComponent, TableComponent, RegisterSheetComponent, DocDialogDialog],
    imports: [
        CommonModule,
        MaterialModule,
        DashboardRoutingModule,
        FlexLayoutModule,
        NgxSkeletonLoaderModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        AngularFireModule.initializeApp(environment.firebase),
        FormsModule,
        ReactiveFormsModule,
        NgxMatIntlTelInputComponent,
        MatTableExporterModule
    ]
})
export class DashboardModule { }
