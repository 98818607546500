import { AfterContentChecked, Component, HostListener, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { DrawerService } from "../../share/service/drawer.service";


@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class AppFullComponent implements AfterContentChecked {
  @ViewChild("drawer") public drawer: MatDrawer;
  screenWidth: number;
  isHideHeader: boolean;
  constructor(
    private drawerService: DrawerService,
  ) {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
  ngAfterContentChecked(): void {
    this.drawerService.drawer = this.drawer;
  }
  active() {
    setTimeout(() => {
      document.getElementById("page-container").scrollTo(0, 0)
    }, 0);
  }

}
