import { Component } from "@angular/core";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent {
  imageList: string[] = [];

  constructor() {
    for (let index = 1; index <= 2; index++) {
      this.imageList.push(`assets/image/sd${index}.jpg`);
    }
  }
}
