import { Component } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { DrawerService } from 'src/app/share/service/drawer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  title: string;
  constructor(public drawerService: DrawerService, router: Router,
  ) {
    router.events.subscribe((event) => {

      if (event instanceof ActivationEnd) {
        const snapshot = event.snapshot;
        let value = snapshot.data.title;
        if (value) {
          this.title = value;
        }
      }

    });
  }
}
