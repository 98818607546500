import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ParticipantService } from 'src/app/share/service/participant.service';
import { DocDialogDialog } from '../doc-dialog/doc-dialog';

interface DialogData {
  id: string,
  name: string,
  table: string,
  email: string,
  contact: string
}
@Component({
  selector: 'app-register-sheet',
  templateUrl: './register-sheet.component.html',
  styleUrls: ['./register-sheet.component.scss']
})
export class RegisterSheetComponent {

  frmGroup: UntypedFormGroup;
  registered: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    private dialog: MatDialog,
    private _bottomSheetRef: MatBottomSheetRef<RegisterSheetComponent>,
    private participantService: ParticipantService
  ) {
    this.initForm();
  }

  private initForm() {
    this.frmGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.name, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      contact: new UntypedFormControl(null, [Validators.required]),
      pdpa: new UntypedFormControl(true, [Validators.requiredTrue])
    });

    if (this.data?.email) {
      this.frmGroup.controls.email.setValue(this.data.email);
    }

    if (this.data?.contact) {
      this.frmGroup.controls.contact.setValue(this.data.contact);
    }
  }

  register() {
    if (!this.frmGroup.valid) {
      return;
    }
    let value = this.frmGroup.value;

    this.participantService.update(this.data.id, value.email, value.contact, value.name);
    this.registered = true;
  }

  openDocDialog() {
    event.preventDefault();
    this.dialog.open(DocDialogDialog)
  }

  close(): void {
    event.preventDefault();
    this._bottomSheetRef.dismiss();
  }
}
