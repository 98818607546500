<div class="drawer-content">
  <!-- <div class="logo-container">
    <img src="assets/image/logo.png" />
  </div> -->
  <mat-nav-list>

    <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/">
      <mat-icon mat-list-icon>people</mat-icon>
      <div class="title" mat-line>Participants</div>
    </mat-list-item>

    <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/table">
      <mat-icon mat-list-icon>event_seat</mat-icon>
      <div class="title" mat-line>Table Seating</div>
    </mat-list-item>



  </mat-nav-list>
</div>