<div class="content-container">

    <div class="banner">
        <img style="border-radius: 10px;" src="assets/image/banner.png" alt="">
    </div>

    <mat-card class="table-list">
        <div class="table-search-container">
            <div class="search-wrapper">
                <mat-icon>search</mat-icon>
                <input placeholder="Search here..." [(ngModel)]="searchText" (ngModelChange)="searchChanged()">
                <mat-icon (click)="resetTextFilter()" *ngIf="searchText">close</mat-icon>
            </div>

            <!-- (click)="exporter.exportTable('xlsx', {fileName: 'Glenfiddich'})" -->
            <button mat-button (click)="exporter.exportTable('xlsx', {fileName: 'Glenfiddich'})"
                style="position: absolute; right: 0;margin-right: 10px;">
                Export
            </button>
        </div>

    </mat-card>

    <mat-card class="table-list" style="padding: 0px;overflow: auto;">
        <table mat-table matTableExporter [dataSource]="filterParticipantList" #exporter="matTableExporter">

            <!-- <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                    PIC
                </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container> -->

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    Name
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>


            <ng-container matColumnDef="table1" class="sm-col">
                <th mat-header-cell *matHeaderCellDef>
                    Table 1
                </th>
                <td mat-cell *matCellDef="let element"> {{element.table1}} </td>
            </ng-container>

            <ng-container matColumnDef="table2" class="sm-col">
                <th mat-header-cell *matHeaderCellDef>
                    Table 2
                </th>
                <td mat-cell *matCellDef="let element"> {{element.table2}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef class="sm-col">
                    Email
                </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>


            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef class="sm-col">
                    Contact No.
                </th>
                <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="sm-col">
                    Date
                </th>
                <td mat-cell *matCellDef="let element"> {{element.date}} </td>
            </ng-container>

            <ng-container matColumnDef="register">
                <th mat-header-cell *matHeaderCellDef>
                    Register
                </th>
                <td mat-cell *matCellDef="let element"><img class="register-btn" (click)="register(element)"
                        src="assets/image/verified-user.png" alt=""> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    No data matching the filter.
                </td>
            </tr>
        </table>

    </mat-card>

</div>