import { NgModule } from "@angular/core";
import { ScreenDirective } from "./device.directive";


@NgModule({

  declarations: [
    ScreenDirective,
  ],
  exports: [
    ScreenDirective,
  ],
})
export class CustomDirectiveModule { }