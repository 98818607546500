<h2 mat-dialog-title>Terms of Service , Privacy Policy and PDPA of the event .</h2>
<mat-dialog-content class="mat-typography">
    <ol>

        <li>
            The Organizer may photograph, video and/or film any activity in connection with the Event. You acknowledge
            that the Organizer has the right to use such publicity photos, videos and/or films, or your name, likeness,
            image and/or voice in any medium and in any reasonable manner it sees fit without further permission from or
            payment to you, for the purpose of promoting this Event (including any outcome), and promoting any Products
            manufactured, distributed and/or supplied by the Organizer.
        </li>
         
        <li>
            The Organizer collects, uses and discloses personal information in order to conduct the Event, as well as
            for promotional, marketing, publicity and research purposes, including sending electronic messages to you or
            telephoning you. The Organizer may, for these aforementioned purposes, also disclose such information to
            third parties, including but not limited to agents, contractors, service providers and suppliers. By
            participating in this Event, you agree that the Organizer may do so in accordance with any applicable
            regulations relating to the protection of personal data.
        </li>

    </ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>