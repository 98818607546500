import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RegisterSheetComponent } from '../register-sheet/register-sheet.component';
import { ParticipantService } from 'src/app/share/service/participant.service';
import { Subject } from 'rxjs';

export interface UserData {
  type: string;
  name: string;
  table1?: string;
  table2?: string
  email: string;
  contact: string;
  date: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {

  displayedColumns: string[] = ['name', 'table1', 'table2', 'email', 'contact', 'date', 'register'];
  participantList: UserData[];
  filterParticipantList: UserData[];
  searchText: string;
  searchChange: Subject<void> = new Subject<void>();

  constructor(
    private _bottomSheet: MatBottomSheet,
    private participantService: ParticipantService,
  ) {
    //this.participantService.deleteAll();
    // this.add();
    this.getData();
  }

  private getData() {
    this.participantService.getListing().subscribe(x => {
      this.participantList = x;
      this.searchChanged();
    });
  }

  searchChanged() {
    let array: UserData[] = [];
    if (this.searchText) {
      let txt = this.searchText.toLowerCase().trim();
      array = this.participantList.filter(x => x.name.toLowerCase().includes(txt) ||
        x.email?.toLowerCase().includes(txt) ||
        x.contact?.toLowerCase().includes(txt) ||
        x.table1?.toLowerCase().includes(txt) ||
        x.table2?.toLowerCase().includes(txt) ||
        //x.type?.toLowerCase().includes(txt) ||
        x.date?.toLowerCase().includes(txt));
    } else {
      array = Object.assign([], this.participantList);
    }
    this.filterParticipantList = array.sort((a, b) => a.name > b.name ? 1 : -1)
    // this.filterParticipantList = array.filter(x => x.date == '28/5/2024').sort((a, b) => a.name > b.name ? 1 : -1)
    //   .concat(array.filter(x => x.date == '29/5/2024').sort((a, b) => a.name > b.name ? 1 : -1));
  }

  resetTextFilter() {
    event.stopImmediatePropagation();
    this.searchText = null;
    this.getData();
  }

  register(data): void {
    this._bottomSheet.open(RegisterSheetComponent, {
      panelClass: "register-sheet",
      data: data,

    });
  }

  add() {
    //this.participantService.deleteAll();
    this.participantService.add();
  }
}



