export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyA02vmJ7raH8qE5sdpvhY4uwE9-J1rPTJs",
        authDomain: "glenfiddich-9812c.firebaseapp.com",
        databaseURL: "https://glenfiddich-9812c-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "glenfiddich-9812c",
        storageBucket: "glenfiddich-9812c.appspot.com",
        messagingSenderId: "871402387648",
        appId: "1:871402387648:web:571c704a0c6b58c9299525",
        measurementId: "G-41SGWSET69"
    }
}

// export const environment = env;
